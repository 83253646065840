<template>
    <a-modal
        :visible="visible"
        :closable="false"
        :centered="true"
        :title="null"
        :footer="null"
        width="300px"
    >
      <div style="font-size: 16px; font-weight: bold;">
        <a-icon type="exclamation-circle" theme="filled" style="color: #FAAD14;font-size: 18px;"/>
        审核认证信息
      </div>
      <div style="display: flex; justify-content: center;margin: 30px 0;">
        <a-radio-group :defaultValue="1" v-model="applyState">
          <a-radio :value="1">认证通过</a-radio>
          <a-radio :value="0">未通过认证</a-radio>
        </a-radio-group>
      </div>
      <div>
        <a-button style="width: 150px;" type="primary" @click="verifyAudit">确认</a-button>
        <a-button style="margin-left: 15px;" @click="$emit('cancelStatus')">取消</a-button>
      </div>
    </a-modal>
</template>

<script>
import * as Settle from '@/api/user/settle'

export default {
  name: "ApplyStatusModal",
  props: {
    applyRow: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      applyState: 0,
      loadingStatus: false
    };
  },
  methods: {
    verifyAudit() {
      const param = {
        type: this.applyRow.authenticationType,
        applyId: this.applyRow.id,
        checkState: this.applyState
      }
      this.loadingStatus = true
      Settle.checkApply(param)
        .then((res) => {
          const { code } = res
          console.log('code', code)
          this.loadingStatus = false
          if (code === 0) {
            this.$message.success('审核成功')
            this.$emit('cancelStatus')
            this.$emit('refresh')
          }
        })
        .catch(e => {
          this.message = e.message || '审核失败'
        })
        .finally(() => {
          this.loadingStatus = false
        })
    },
    cancelModal(){
      this.$emit('refresh');
    }
  },
};
</script>
